import React from 'react';

import { appstore } from '../stores/appstore';
import { useRouter } from 'next/router';
import Image from 'next/image';
import { ArrowRightIcon, DownloadIcon } from '@heroicons/react/solid';
import Link from 'next/link';

function Home() {
  const router = useRouter();

  if (appstore.runtime.platform === 'desktop') {
    router.replace('/app');
    return (<></>);
  }

  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex prose max-w-6xl mx-auto w-full">
        <div className='flex items-center justify-between h-16 md:h-20 w-full'>
          <Link href="/">
            <a><Image src="/logo-bg.png" width="48" height="48" /></a>
          </Link>
          <div className='flex flex-row items-center'>
            <Link href="/how-it-works">
              <a className=''>
                <span>How It Works</span>
              </a>
            </Link>
            <Link href="/app">
              <button className='border px-4 py-2 rounded-lg hover:bg-gray-100 ml-4 flex flex-row items-center'>
                <span>Try It</span>
                <ArrowRightIcon width={12} height={12} className='ml-2' />
              </button>
            </Link>
          </div>
        </div>
      </div>

      <div className="flex flex-col prose mx-auto mt-32 text-center">
        <h1 className='text-6xl m-0'>Flowpilot</h1>
        <h2>Creation is hard<br/>Flowpilot unlocks the creative process</h2>
        <div className='flex flex-row justify-center mt-2'>
          <Link href="/app">
            <button className='px-4 py-2 rounded-lg bg-green-400 hover:bg-green-500 ml-3 flex flex-row items-center text-white'>
              <span>Go to the web app</span>
              <ArrowRightIcon width={12} height={12} className='ml-2' />
            </button>
          </Link>
          <Link href="/api/download">
            <button className='border px-4 py-2 rounded-lg hover:bg-gray-100 ml-3 flex flex-row items-center'>
              <span>Download the Mac app</span>
              <DownloadIcon width={12} height={12} className='ml-2' />
            </button>
          </Link>
        </div>
        <p className='prose-sm text-gray-500'>No sign-up required</p>
      </div>

      <div className="flex flex-col mx-auto text-center w-2/3 mt-16">
        <Image src="/screenshot.png" width={4668} height={3030} />
      </div>

      <div className="flex flex-col prose mx-auto w-full mt-16">
        <h3>Develop your goals</h3>
        <p>Creation starts by knowning what you want. But knowing what you want can be impossibly hard. Flowpilot gives you the space to develop where you want to be at the end of a project.</p>
      </div>

      <div className="flex flex-col prose mx-auto w-full mt-16">
        <h3>Plan with the end in mind</h3>
        <p>Armed with a completable goal, you can start to work backwards. Every goal is made up of several milestones along the way. Use Flowpilot to break down your larger goal into sub-tasks and sub-sub tasks. Eventually, you will arrive at a step that can be taken today.</p>
      </div>

      <div className="flex flex-col prose mx-auto w-full mt-16">
        <h3>Execute on your vision</h3>
        <p>No tool can make work effortless, but Flowpilot should keep you focused on the next thing to do. Enter flow more easily by working within the full context of each task.</p>
      </div>

      <div className="flex flex-col prose text-center mx-auto w-full mt-24 mb-8">
        <Link href="/how-it-works">
          <a>
            <span>Learn how to write your way to your goals with Flowpilot</span>
          </a>
        </Link>
      </div>

      <div className='flex flex-col prose text-center max-w-6xl mx-auto w-full my-16 italic'>
        <hr />
        <p>Copyright © 2022 Bolder Research. All rights reserved.</p>
      </div>
    </div>
  );
}

export default Home;